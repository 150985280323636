import PaginationComponent from "../transactions";

function Transactions() {
  return (
    <div className="App">
      <div>
        <PaginationComponent />
      </div>
    </div>
  );
}

export default Transactions;