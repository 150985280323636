import apiRequest from "../services/ServiceRequest";
import * as URL from '../../src/services/ServiceConstant';
export const fetchNotification = async () => {
    let platformsettings = await apiRequest([],URL.BASE_URL+'/platformSettings','get');
    let notificationsFlag = platformsettings?.data?.[18]?.enabled;
    if(notificationsFlag==true){
        let notifications = await apiRequest([],URL.BASE_URL+'/Notifications?filter={"where":{"status":"open","type":"web"}}','get');
        if(notifications?.data.length>0){ var item = notifications?.data[0];}
        if(item?.id!=undefined || item?.id!=null || item?.id==''){
            await apiRequest({id : item?.id,status : "closed"}, URL.BASE_URL+'/Notifications','patch');
        }
        return item;
    }else{
        return undefined;
    }
}