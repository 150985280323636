import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/css/custom.css';
import './assets/css/icofont.css';
import './assets/fonts/icofont.eot';
import './assets/fonts/icofont.svg';
import './assets/fonts/icofont.ttf';
import './assets/fonts/icofont.woff';
import './assets/fonts/icofont.woff2';
import './assets/css/jquery.mCustomScrollbar.css';
import './routes/AuthRoutes';
import './components/forgotpassword';
import 'react-toastify/dist/ReactToastify.css';
ReactDOM.render(
  <App />,document.getElementById('root')
);
reportWebVitals();
