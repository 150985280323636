export default function PencilImg(){
    return (
        <svg id="pencil" xmlns="http://www.w3.org/2000/svg" width="13.482" height="13.482" viewBox="0 0 13.482 13.482">
            <g id="Group_6983" data-name="Group 6983" transform="translate(0 9.303)">
                <g id="Group_6982" data-name="Group 6982">
                    <path id="Path_3644" data-name="Path 3644" d="M1.348,353.28,0,357.459l4.179-1.348Z" transform="translate(0 -353.28)" fill="#555" />
                </g>
            </g>
            <g id="Group_6985" data-name="Group 6985" transform="translate(2.295 1.756)">
                <g id="Group_6984" data-name="Group 6984" transform="translate(0 0)">
                    <rect id="Rectangle_1029" data-name="Rectangle 1029" width="9.303" height="4.045" transform="translate(0 6.578) rotate(-45)" fill="#555" />
                </g>
            </g>
            <g id="Group_6987" data-name="Group 6987" transform="translate(9.842)">
                <g id="Group_6986" data-name="Group 6986">
                    <path id="Path_3645" data-name="Path 3645" d="M377.2,2.09,375.31.2a.652.652,0,0,0-.944,0l-.607.607,2.831,2.831.607-.607A.652.652,0,0,0,377.2,2.09Z" transform="translate(-373.76 0)" fill="#555" />
                </g>
            </g>
        </svg>
    );
}