import PaginationComponent from "../productreviews";

function ProductReviews() {
  return (
    <div className="App">
      <div>
        <PaginationComponent />
      </div>
    </div>
  );
}

export default ProductReviews;