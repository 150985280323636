import { useHistory } from 'react-router-dom';
import * as URL from '../../src/services/ServiceConstant';
import apiRequest from "../services/ServiceRequest";
import { dynamicStylesheet } from '../utils/common';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as EmailValidator from "email-validator";
export const Forgot = () => {
    useEffect(async () => {
        let dynamicCSS = await dynamicStylesheet();
        var style = document.createElement('style');
        style.innerHTML = dynamicCSS.css;
        document.getElementsByTagName('head')[0].appendChild(style);
    }, []);
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    let history = useHistory();
    const submitForm = async (props) => {
        if (email === "") {
            setError("Fields are required");
            return;
        }
        if (!email) {
            setError("Required");
            return;
        }
        if (!EmailValidator.validate(email)) {
            setError("Invalid email address.");
            return;
        }
        const data = {
            email: email,
        };
        let response = await apiRequest(data, URL.BASE_URL +'/locationmanager-bo-forgot-password', 'post')
        alert("Sent change password link to the email!!");
        toast();
        window.location.href = "/";
    };
    return (
        <html lang="en">
            <head>
                Food Delivery
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                <link rel="shortcut icon" type="image/png" href="img/logo.png" />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
                <link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap" rel="stylesheet" />
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous" />
                <link rel="stylesheet" href="css/icofont.css" />
                <link rel="stylesheet" href="css/jquery.mCustomScrollbar.css" />
                <link rel="stylesheet" href="css/custom.css" />
                <title>Food Delivery</title>
            </head>
            <body>
                <div class="account_form">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <div class="account_icon_wrap">
                                <div class="account_icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32.602" height="43.469" viewBox="0 0 32.602 43.469">
                                        <path id="padlock" d="M31.526,16.3H30.168V10.867a10.867,10.867,0,0,0-21.734,0V16.3H7.075A4.08,4.08,0,0,0,3,20.376V39.394a4.08,4.08,0,0,0,4.075,4.075H31.526A4.08,4.08,0,0,0,35.6,39.394V20.376A4.08,4.08,0,0,0,31.526,16.3Zm-19.47-5.434a7.245,7.245,0,1,1,14.49,0V16.3H12.056Zm9.056,19.42v4.126a1.811,1.811,0,0,1-3.622,0V30.287a3.622,3.622,0,1,1,3.622,0Z" transform="translate(-3)" fill="#555" />
                                    </svg>
                                </div>
                            </div>
                            <h2 class="account_page_title">Forgot Password</h2>
                            <p class="forgot_pass_info">Don't worry. Resetting your password is easy, just tell us the email address that was used to create this account.</p>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Email Address</label>
                                <input type="text" class="form-control" value={email} onChange={e => setEmail(e.target.value)} />
                            </div>
                        </div>
                        {error && <><small style={{ color: 'red', "textAlign": "center", "margin-left": "17px" }}>{error}</small><br /></>}<br />
                        <div class="col-md-12">
                            <div class="form-group">
                                <button type="submit" class="btn_sty_1" onClick={submitForm}>Submit</button>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group text-center">
                                <a href="/" class="account_bottom_link" onClick={() => history.push('/')}>Back To Login</a>
                            </div>
                        </div>
                    </div>
                </div>
                <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                <script src="js/jquery.mCustomScrollbar.concat.min.js"></script>
            </body>
        </html>
    );
}
export default Forgot;