import { useState, useEffect } from "react";
import * as URL from '../../src/services/ServiceConstant';
import apiRequest from "../services/ServiceRequest";
import Moment from 'react-moment';
import "../assets/css/pagination.css";
import { useHistory } from 'react-router-dom';
import { setLocalizationSettings } from '../utils/common.js';
const ProductReviews = (props) => {
    let history = useHistory();
    const [get_data, setCustomers] = useState("");
    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(10);
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
    const [global_date, setGlobalDateFormat] = useState('');
    const handleClick = (event) => {
        setcurrentPage(Number(event.target.id));
    };
    const pages = [];
    for (let i = 1; i <= Math.ceil(get_data.length / itemsPerPage); i++) {
        pages.push(i);
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = get_data.slice(indexOfFirstItem, indexOfLastItem);
    const renderPageNumbers = pages.map((number) => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
            return (
                <li
                    key={number}
                    id={number}
                    onClick={handleClick}
                    className={currentPage == number ? "active" : null}
                >
                    {number}
                </li>
            );
        } else {
            return null;
        }
    });
    useEffect(async () => {
        var localSettings = await setLocalizationSettings();
        await setGlobalDateFormat(localSettings?.[0]);
        let productcustomereviews = await apiRequest([], URL.BASE_URL + '/ReviewRatings?filter={"include":["customer"]}', 'get');
        let reviews = productcustomereviews.data.filter(function (item) {
            return item.productId == props.productId;
        })
        setCustomers(reviews);
    }, []);
    const renderData = (data) => {
        return (
            <div>
                {
                    data.length > 0
                        ? data?.map((item, index) => {
                            return (
                                <>
                                <div class="m_staff_top2" style={{ "display": "flex" }}>
                                    <div class="customer_box"><img class="product_review_customer" src={item?.customer?.profile_image} alt="" />
                                    </div>
                                    <div class="m_staff_info">
                                        <div class="row">
                                            <div style={{ "display": "flex" }} class="col-md-12">
                                                <h2 style={{ "width": "300px", "font-size": "20px" }}>
                                                    {item?.customer?.first_name ? item?.customer?.first_name : item?.customer?.firstname}
                                                </h2>
                                                <div style={{ "margin-left": "70px", "font-size": "15px" }}>
                                                    {
                                                        [1, 2, 3, 4, 5].map((row, index) => {
                                                            return (<span className={row > item?.rating_point[0] ? "fa fa-star star_rating_unchecked" : "fa fa-star star_rating_checked"}></span>);
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        

                                        <div class="row">
                                            <div class="col-md-12">
                                                <h3><Moment format={global_date ? global_date : " DD MMM  YYYY-h:mm A"}>{item.review_date}</Moment></h3>
                                                <p style={{"word-wrap":"break-word"}}>{item.message}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    <hr></hr>
                                </>
                            )
                        })
                        : <div align="center" style={{ 'font-size': '16' }} >{"No reviews available for this product"}</div>
                }
            </div>
        );
    };
    const handleCallback = async (childData) => {
    }
    const handleNextbtn = () => {
        setcurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageNumberLimit) {
            setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    };
    const handlePrevbtn = () => {
        setcurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageNumberLimit == 0) {
            setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    };
    let pageIncrementBtn = null;
    if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
    }
    let pageDecrementBtn = null;
    if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
    }
    const handleLoadMore = () => {
        setitemsPerPage(itemsPerPage + 5);
    };
    return (
        <div>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>

            <div class="m_staff_box m_staff_cust">
                {renderData(currentItems)}
            </div>
            <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
            <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
            <script src="js/jquery.mCustomScrollbar.concat.min.js"></script>
            {get_data.length > 0 ? <ul className="pageNumbers">
                <li onClick={handlePrevbtn}
                    className={currentPage == pages[0] ? "disallow" : "allow"}>
                    {"<"}
                </li>
                {pageDecrementBtn}
                {renderPageNumbers}
                {pageIncrementBtn}
                <li onClick={handleNextbtn}
                    className={currentPage == pages[pages.length - 1] ? "disallow" : "allow"}>
                    {">"}
                </li>
            </ul> : ''}
            
        </div>
    )
}
export default ProductReviews;