import PaginationComponent from "../restaurant_manager_products";

function RestaurantManagerProducts() {
  return (
    <div className="App">
      <div>
        <PaginationComponent />
      </div>
    </div>
  );
}

export default RestaurantManagerProducts;