import PaginationComponent from "../locationmanager";

function LocationManager() {
  return (
    <div className="App">
      <div>
        <PaginationComponent />
      </div>
    </div>
  );
}

export default LocationManager;