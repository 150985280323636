import 'react-toastify/dist/ReactToastify.css';
export const Success = () => {
    return (
        <html lang="en">
            <head>
                Food Delivery
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                <link rel="shortcut icon" type="image/png" href="img/logo.png" />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
                <link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap" rel="stylesheet" />
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous" />
                <link rel="stylesheet" href="css/icofont.css" />
                <link rel="stylesheet" href="css/jquery.mCustomScrollbar.css" />
                <link rel="stylesheet" href="css/custom.css" />
                <title>Food Delivery</title>
            </head>
            <body>
                <div class="account_form">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <div class="account_icon_wrap">
                                <div class="account_icon">
                                    <img src='./success.png' />
                                </div>
                            </div>
                            <h2 class="account_page_title">Password Setup Completed!!</h2>
                            <p class="forgot_pass_info">Thanks for setting your password. Please start using the app using your credentials.</p>
                        </div>
                    </div>
                </div>
                <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                <script src="js/jquery.mCustomScrollbar.concat.min.js"></script>
            </body>
        </html>
    );
}
export default Success;