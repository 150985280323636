import Checkbox from "react-custom-checkbox";
import * as Icon from "react-icons/fi";
function OrdersAvailableDrivers(props) {
    return (
        <div class="modal-body driver-modal-body">
            <div class="driver_list">
                {
                    props.drivers.length > 0
                        ? props.drivers?.map((item, index) => {
                            return (
                                <ul style={{ "padding-left": "30px" }}>
                                    <li class="driver_avl">
                                        <div class="assign_driver_pic">
                                            <img src={item?.profile_image ? item?.profile_image : './user.jpeg'} alt="" />
                                        </div>
                                        <div class="assign_driver_info">
                                            <h3>{item.first_name}</h3>
                                            <h4><strong>Phone: </strong> {item.mobile}</h4>
                                        </div>
                                        <Checkbox
                                            checked={item.checked}
                                            icon={
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        backgroundColor: "white",
                                                        alignSelf: "stretch",
                                                    }}
                                                >
                                                    <icon></icon>
                                                    <Icon.FiCheck color="#000000" size={20} />
                                                </div>
                                            }
                                            onChange={() => props.handleCheckbox(item)}
                                            borderColor={"#343a40"}
                                            borderRadius={20}
                                            style={{ overflow: "hidden", "margin": "0px 0px 0px -426px", "border-color": "#343a40 !important" }}
                                            size={20}
                                            label=""
                                        />
                                        <div class="assign_driver_check"><i class="icofont-check"></i><input type="checkbox" class="checkbox-round" /></div>
                                    </li>
                                </ul>
                            )
                        })
                        : <div align="center" style={{ 'font-size': '16' }} >No Drivers found</div>
                }
            </div>
        </div>
    );
}
export default OrdersAvailableDrivers;