import { Modal, Dropdown } from 'react-bootstrap';
const OrdersEdit = (props) => {
    return (
        <div class="modal-content">
            <Modal.Header closeButton>
                <Modal.Title>Edit Order</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="modal-body">
                    <div class="edit_order_sec">
                        <div class="edit_order_pic">
                            <img src={props.values.product_image} alt="" />
                        </div>
                        <div class="edit_odr_info">
                            <h2 class="r_name">{props.values.product_name}</h2>
                            <strong class="edit_price">{props.values.product_price}</strong>
                        </div>
                        <div class="edit_order_qty">
                            <div style={props.values.inc_dec_buttons == false ? { "pointer-events": "none" } : {}} class="edit_minus" onClick={() => props.values.decrementQuantity(props.values.orderId, props.values.product_price, props.values.order_detail_id)}><i class="icofont-minus"></i></div>
                            <input class="edit_text" type="text" value={props.values.product_quantity} />
                            <div style={props.values.inc_dec_buttons == false ? { "pointer-events": "none" } : {}} class="edit_plus" onClick={() => props.values.incrementQuantity(props.values.orderId, props.values.product_price, props.values.order_detail_id)}><i class="icofont-plus"></i></div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {/* <div class="col-md-12">
                    <div class="form-group mb-0 staff_profile_btn_group">
                        <button type="submit" class="btn_sty_1" style={{ "width": '110px;' }} onClick={() => props.values.updateOrderQuantity()} >Submit</button>
                    </div>
                </div> */}
            </Modal.Footer>
        </div>
    );
}
export default OrdersEdit;