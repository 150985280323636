function StaffList(props) {
    return (
        <div>
            {
                props.values.data.length > 0
                    ? props.values.data?.map((item, index) => {
                        var ratingscount = 0;
                        for (var i = 0; i < item?.ratings?.length; i++) {
                            ratingscount = ratingscount + item?.ratings?.rating_point;
                        }
                        item.rating = ((item?.ratings?.length * 5) + (ratingscount)) / 2;
                        return (
                            <div class="m_staff_box">
                                <div class="m_staff_top">
                                    <div class="img_box"><img src={item['profile_image'] ? item['profile_image'] : './img-placeholder.png'} alt="" /></div>
                                    <div class="m_staff_info">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="d-flex" style={{ "display": "flex" }}>
                                                    <h2 class="r_name">{item['first_name'].length > 20 ? item['first_name'].substring(0, 20) + '...' : item['first_name']}</h2>

                                                </div>
                                            </div>
                                            <div class="col-md-6 r_align_right view_id_btn_wrap">
                                                <div class="pr-3">
                                                    {(item["approval_status"] == "pending") ? <p class="approval_status_btn1">Pending</p> : ''}
                                                    {(item["approval_status"] == "approved") ? <p class="approval_status_btn2">Approved</p> : ''}
                                                    {(item["approval_status"] == "blocked") ? <p class="approval_status_btn3">Blocked</p> : ''}
                                                </div>
                                                <div href="#" class="panel-heading active" class="view_id_btn" data-toggle="modal" data-target="#idcardModal" onClick={e => props.values.handleShowID(item['ID'])}  ><a href="#" class="hrefcol">View ID</a> </div>
                                            </div>
                                            <div class="col-md-6">
                                                <h4><span>Phone:</span> {item['mobile']}</h4>
                                            </div>
                                            <div class="col-md-6 r_align_right">
                                                <h4><span>Email:</span> {item['email']}</h4>
                                            </div>
                                        </div>
                                        <div class="m_staff_bottom">
                                            <h4><span>Assigned Location:</span> {
                                                props.values.DisplayLocations(item.locs, props.values.locations)
                                            }</h4>
                                        </div>
                                        <div style={{ "text-align": "right" }} class="btn_group">
                                            <i>Average Rating : &nbsp;
                                                {
                                                    [1, 2, 3, 4, 5].map((row, index) => {
                                                        return (<span className={row > item.rating ? "fa fa-star star_rating_unchecked" : "fa fa-star star_rating_checked"}></span>);
                                                    })
                                                }</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <button class="grey_btn" data-target="#edit_staffModal" onClick={e => props.values.onEdit(item)}>Edit</button>&nbsp;&nbsp;
                                            <button class="grey_btn" onClick={e => props.values.onDelete(item.id)}>Remove</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    : <div align="center" style={{ 'font-size': '16' }} >{props.values.view_show_msg}</div>
            }
        </div>
    );
}
export default StaffList;