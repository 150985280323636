import { useState, useEffect } from "react";
import * as URL from '../../src/services/ServiceConstant';
import apiRequest from "../services/ServiceRequest";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PlacesAutocomplete, {geocodeByAddress,getLatLng,geocodeByPlaceId} from "react-places-autocomplete";
const BusinessProfile = (props) => {
    const [logo, setLogo] = useState('');
    const [business_name, setBusinessName] = useState('');
    const [corporate_address, setCorporateAddess] = useState('');
    const [support_email, setSupportEmail] = useState('');
    const [support_phone, setSupportPhone] = useState('');
    const [map_loaded, setMapLoaded] = useState(false);
    useEffect(async () => {
        let projectSettings = await apiRequest([], URL.BASE_URL + '/projectSettings', 'get');
        let extracted = projectSettings.data?.extracted[0];
        setLogo(extracted?.logo);
        setBusinessName(extracted?.business_name);
        setCorporateAddess(extracted?.corporate_address);
        setSupportEmail(extracted?.support_email);
        setSupportPhone(extracted?.support_phone);
        setMapLoaded(true);
    }, []);
    const handleSelect = async value => {
        setCorporateAddess(value);
    };
    const _onChangeLogo = async (logo) => {
        if(logo.type!="image/jpeg" && logo.type!="image/jpg" && logo.type!="image/png")
        {
            alert("Please upload the following file types: jpeg, and png.");
            return false;
        }
        var _size = logo.size;
        if(_size>1000000)
        {
            alert("The size limit for images is 1MB. Reduce the file size and try again.");
            return false;
        }
        let formData = new FormData();
        formData.append('logo', logo);
        let logo_response = await apiRequest(formData, URL.UPLOAD_IMAGE, 'post')
        if (logo_response.error) {
            alert("'Failed', response.error.message");
            return;
        }
        setLogo(URL.image_url + logo_response.data.logo)
    }
    const updateBusinessProfile = async() => {
        if(logo=="" || business_name=="" || corporate_address=="" || support_email=="" || support_phone==""){
            alert("please enter business profile details!!");
            return false;
        }else{
            await apiRequest({
                logo : logo,
                business_name : business_name,
                corporate_address : corporate_address,
                support_email : support_email,
                support_phone : support_phone
            },URL.BASE_URL+'/projectSettings/logo','post');
            toast.success("Updated successfully!!");
            setTimeout(function(){
                window.location.reload();
            },1000);
        }
    }
    return (
        <div>
            <h4>Business Profile</h4>
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Logo</label>
                        <input type="file" name="logo" onInput={(e) => _onChangeLogo(e.target.files[0])} style={{"display" : "block"}} />
                        {logo ? <div><br></br><img src={logo}></img></div> : ''}
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                    <label>Business Name</label>
                    <input type="text" value={business_name} onChange={(e) => setBusinessName(e.target.value)} name="business_name" class="form-control" />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                    <label>Corporate Address</label>
                    {(map_loaded==true) ? <PlacesAutocomplete
                                                                    searchOptions = {
                                                                        {
                                                                            types: ['address']
                                                                        }
                                                                    }
                                                                    value={corporate_address}
                                                                    onChange={setCorporateAddess}
                                                                    onSelect={handleSelect}
                                                                    >
                                                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                                    <div>
                                                                        
                                                                        <input className="form-control" {...getInputProps({ placeholder: "Type address" })} />

                                                                        <div className="autocomplete-dropdown-container">
                                                                        {loading && <div>Loading...</div>}
                                                                        {suggestions.map(suggestion => {
                                                                            const className = suggestion.active
                                                                            ? 'suggestion-item--active shadow bg-white rounded'
                                                                            : 'suggestion-item shadow bg-white rounded';
                                                                            const style = suggestion.active
                                                                            ? { backgroundColor: '#e9ecef', cursor: 'pointer', width : '238px'}
                                                                            : { padding : '3px', backgroundColor: '#e9ecef', "box-shadow" : "1px 1px #e9ecef", cursor: 'pointer', "margin" : "0px", "border" : "1px solid #e9ecef", "border-radius" : "1px",  width : '350px' }
                                                                            return (
                                                                            <div
                                                                                {...getSuggestionItemProps(suggestion, {
                                                                                className,
                                                                                style,
                                                                                })}
                                                                            >
                                                                                <span><svg xmlns="http://www.w3.org/2000/svg" width="22" height="12" viewBox="0 0 14.992 19.989">
                                                                                        <g id="placeholder" transform="translate(-64)">
                                                                                            <g id="Group_6964" data-name="Group 6964" transform="translate(64)">
                                                                                                <g id="Group_6963" data-name="Group 6963">
                                                                                                    <path id="Path_3633" data-name="Path 3633" d="M71.5,0A7.5,7.5,0,0,0,64,7.5c0,5.2,6.766,12.022,7.054,12.311a.627.627,0,0,0,.885,0c.287-.289,7.054-7.115,7.054-12.311A7.5,7.5,0,0,0,71.5,0Zm0,18.461c-1.492-1.6-6.247-7-6.247-10.965a6.247,6.247,0,0,1,12.493,0C77.743,11.461,72.988,16.865,71.5,18.461Z" transform="translate(-64)" />
                                                                                                </g>
                                                                                            </g>
                                                                                            <g id="Group_6966" data-name="Group 6966" transform="translate(67.748 3.748)">
                                                                                                <g id="Group_6965" data-name="Group 6965">
                                                                                                    <path id="Path_3634" data-name="Path 3634" d="M163.748,96a3.748,3.748,0,1,0,3.748,3.748A3.753,3.753,0,0,0,163.748,96Zm0,6.247a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,163.748,102.247Z" transform="translate(-160 -96)" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </svg>{suggestion.description}</span>
                                                                            </div>
                                                                            );
                                                                        })}
                                                                        </div>
                                                                    </div>
                                                                    )}
                                                                </PlacesAutocomplete> : ''}
                    
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Support email</label>
                        <input type="text" value={support_email} onChange={(e) => setSupportEmail(e.target.value)} name="support_email" class="form-control" />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                    <label>Support Phone</label>
                    <input type="text" value={support_phone} onChange={(e) => setSupportPhone(e.target.value)} name="support_phone"  class="form-control" />
                    </div>
                </div>
                <div class="col-md-12">
                <button onClick={() => updateBusinessProfile()} type="submit" class="btn_sty_1">Update Business Profile</button>
                </div>
            </div>
            <hr></hr>
        </div>
        
    )
}
export default BusinessProfile;