import { useState, useEffect } from "react";
import Header from "./header"
import Sidebar from "./menu"
import * as URL from '../../src/services/ServiceConstant';
import apiRequest from "../services/ServiceRequest";
import Moment from 'react-moment';
import "../assets/css/pagination.css";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setLocalizationSettings, LoaderGif, sortCustomers, dynamicStylesheet } from '../utils/common.js';
import CustomersList from "./list/customers";
const Customers = (props) => {
    let history = useHistory();
    const [get_data, setCustomers] = useState("");
    const [customers, setCustomersData] = useState("");
    const [customer_name, setCustomerName] = useState("");
    const [highlightColor, setHighLightColor] = useState('');
    const [view_show_msg, show_mesage] = useState("");
    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(25);
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
    const [global_date, setGlobalDateFormat] = useState('');
    const [loading, setLoading] = useState(true);
    const handleClick = (event) => {
        setcurrentPage(Number(event.target.id));
    };
    const pages = [];
    for (let i = 1; i <= Math.ceil(get_data.length / itemsPerPage); i++) {
        pages.push(i);
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = get_data.slice(indexOfFirstItem, indexOfLastItem);
    const renderPageNumbers = pages.map((number) => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
            return (
                <li
                    key={number}
                    id={number}
                    onClick={handleClick}
                    className={currentPage == number ? "active" : null}
                >
                    {number}
                </li>
            );
        } else {
            return null;
        }
    });
    useEffect(async () => {
        let dynamicCSS = await dynamicStylesheet();
        setHighLightColor(dynamicCSS?.highlightColor);
        var localSettings = await setLocalizationSettings();
        await setGlobalDateFormat(localSettings?.[0]);
        GetCustomers();
    }, [global_date]);
    async function GetCustomers() {
        let endpoint = URL.CUSTOMER + '?filter={"order":"id desc"}'
        var get_loc = await apiRequest([], endpoint, 'get')
        if (get_loc == null || get_loc == undefined || get_loc.data === '{}' || Object.entries(get_loc.data).length === 0 || get_loc === null || get_loc === undefined || get_loc.length <= 0 || get_loc.data.length <= 0) {
            show_mesage("No records found")
            setLoading(false);
        }
        else {
            var customers = await sortCustomers(get_loc.data);
            setCustomers(customers)
            setCustomersData(customers)
            setLoading(false);
        }
    }
    const notify = (message) => {
        toast.success(message)
    }
    const renderData = (data) => {
        return (
            data.length > 0 ?
                <CustomersList values={{
                    data: data,
                    global_date: global_date,
                    getCustomerOrders: getCustomerOrders,
                    blockCustomer: blockCustomer,
                    view_show_msg: view_show_msg
                }} /> : <center><b>No records found!!</b></center>
        );
    };
    const handleCallback = async (childData) => {
    }
    const getCustomerOrders = (id) => {
        history.push({
            pathname: '/orders',
            search: '?customer=' + id,
            state: { id: id }
        })
    }
    const blockCustomer = async (customerId, blockStatus) => {
        var windowconfirm = '';
        if (blockStatus == 'blocked') {
            windowconfirm = window.confirm('Are you sure?');
        }
        else {
            windowconfirm = window.confirm('Are you sure?');
        }
        if (windowconfirm) {
            var apiEndPoint = URL.CHANGECUSTOMERSTATUS;
            var apiBody = {
                customerId: customerId,
                block_status: blockStatus
            };
            let apiResponse = await apiRequest(apiBody, apiEndPoint, 'patch');
            if (apiResponse) {
                if (blockStatus == 'blocked') {
                    notify("Blocked successfully");
                } else {
                    notify("Unblocked successfully");
                }
                GetCustomers();
            }
        }
        else {
            return false;
        }
    };
    const filter = (e) => {
        const keyword = e
        if (keyword != '') {
            let USERS = [...customers]
            var filteredUsers = customers.filter(item => {
                const query = keyword.toLowerCase();
                if (item.firstname != null && item.firstname != undefined) {
                    return (
                        item.firstname.toLowerCase().startsWith(keyword.toLowerCase())
                    )
                }
            });
            setCustomers(filteredUsers);
        }
        else {
            setCustomers(customers);
        }
        setCustomerName(keyword);
    }
    const handleNextbtn = () => {
        setcurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageNumberLimit) {
            setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    };
    const handlePrevbtn = () => {
        setcurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageNumberLimit == 0) {
            setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    };
    let pageIncrementBtn = null;
    if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
    }
    let pageDecrementBtn = null;
    if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
    }
    const handleLoadMore = () => {
        setitemsPerPage(itemsPerPage + 5);
    };
    const searchCustomer = (value) => {
        let customerss = get_data;
        const searchString = value?.toLowerCase();
        if (searchString?.length > 0) {
            const filteredResults = customerss?.filter(item => {
                if (item?.firstname) {
                    return (
                        item?.firstname?.toLowerCase()?.includes(searchString)
                    )
                } else {
                    return (
                        item?.first_name?.toLowerCase()?.includes(searchString)
                    )
                }
            });
            if (filteredResults) {
                setCustomers(filteredResults);
                setCustomersData(filteredResults);
                setcurrentPage(1);
            } else {
                GetCustomers();
            }
        } else {
            GetCustomers();
        }
    }
    return (
        <div>
            <html lang="en">
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                <div class="sticky">
                    <Header parentCallback={handleCallback} />
                </div>
                <body>
                    <div class="middle_block">
                        <Sidebar />
                        <div class="main_content">
                            <div class="container-fluid">
                                <div class="manage_staff">
                                    <div class="sec_title">
                                        <h2 class="head_sty1">Customers</h2>
                                        <div class="search_box_wrap search_customer">
                                            <div style={{ "display": "flex" }}>
                                                <div style={{ "margin-left": "84px", "display": "flex" }}>
                                                    <input type="text" onChange={(e) => searchCustomer(e.target.value)} id="searchbar" id="search" class="form-control" placeholder="Type Name to Search..." />
                                                    <i style={{ "background": highlightColor ? highlightColor : "#fff" }} class="fa fa-search fa-border custom_icon"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {(loading == false) ? renderData(currentItems) : <img className="loading" src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif"></img>}

                                    <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>
                                    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>
                                    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>
                                    <script src="js/jquery.mCustomScrollbar.concat.min.js"></script>
                                </div>
                            </div>
                        </div>
                    </div>
                </body>
            </html>
            <ul className="pageNumbers">
                <li onClick={handlePrevbtn}
                    className={currentPage == pages[0] ? "disallow" : "allow"}>
                    {"<"}
                </li>
                {pageDecrementBtn}
                {renderPageNumbers}
                {pageIncrementBtn}
                <li onClick={handleNextbtn}
                    className={currentPage == pages[pages.length - 1] ? "disallow" : "allow"}>
                    {">"}
                </li>
            </ul>
        </div>
    )
}
export default Customers;