import { useState, useEffect } from "react";
import Header from "./header"
import Sidebar from "./menu"
import * as URL from '../../src/services/ServiceConstant';
import apiRequest from "../services/ServiceRequest";
import { toast } from 'react-toastify';
import { getUser,fetchUpload } from '../utils/common';
import 'react-toastify/dist/ReactToastify.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const FAQS = (props) => {
    const [text, setText] = useState("");
    const [id, setId] = useState("");
    useEffect(async () => {
       var user = getUser();
       let apiBody = {
        "content_name" : "faqs",
        "businessownerId" : user
       };
        var getFaqs = await apiRequest(apiBody, URL.FAQS, 'post')
        var responseLength = getFaqs.data.models.length;
        if (getFaqs === [] || responseLength==0 || Object.entries(getFaqs).length === 0 || getFaqs == null || getFaqs == undefined || getFaqs.length <= 0 || getFaqs.length <= 0) {
        }
        else {
            let temp_Array = getFaqs.data.models[0].content_html;
            let content_id = getFaqs.data.models[0].id;
            setId(content_id);
            setText(temp_Array)
        }
    }, [])
    const notify = (message) => {
        toast.success(message)
    }
    const updateContent = async (id,text) => {
        var apiBody = {
            appContentId : id,
            content_html : text
        };
        await apiRequest(apiBody, URL.UPDATEFAQS, 'patch');
        notify("Updated successfully");
    }
    const handleCallback = async (childData) => {

    }
    function uploadAdapter  (loader)  {
        return {
            upload : () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    fetchUpload(loader,resolve,reject,body);
                });
            }
        }
    }
    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        }
    }
    return (
        <div>
            <html lang="en">
                <div class="sticky">
                    <Header parentCallback={handleCallback} />
                </div>
                <body>
                    <div class="middle_block">
                        <Sidebar />
                        <div class="main_content">
                            <div class="container-fluid">
                                <div class="content_block">
                                    <div class="sec_title">
                                        <h2 class="head_sty1">Frequently Asked Questions (FAQ)</h2>
                                    </div>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={text}
                                        onReady={editor => {
                                        }}
                                        config = {{
                                            extraPlugins : [uploadPlugin]
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setText(data)
                                        }}
                                    />
                                    <br/>
                                    <div class="btn_group">
                                    <button onClick={() => updateContent(id,text)} type="button" href="#"  class="btn_sty_1">Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </body>
            </html>
        </div>
    )
}
export default FAQS;